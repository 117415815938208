import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
//import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
//import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  //const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="Ral2E1sSCbk" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        天文好きの少年、ユータは図書館で不思議な本「アストロペディア」と出会う。そして「アストロペディア」に導かれて天文に関する知識や最新情報を学んでいく。月は一番近くにある天体で地球の衛星です。月の大きさは地球の4分の1もあります。月はどうやってできたのか。その謎を解き明かしていく。
      </p>
    );
  };

  const summaryData = {
    scopes: ['All'],
    awards: ['映文連アワード2009 部門優秀賞 ソーシャル・コミュニケーション部門'],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      {/*
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(1, 2)} column={1} />
      </WorkDetailImageGallery>
      */}
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/astropedia"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/astropedia.*"}) {
      publicURL
    }
  }
`;
